// File#: _1_reveal-effects
// Usage: codyhouse.co/license
(function() {
	var fxElements = document.getElementsByClassName('reveal-fx');
	var intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
	if(fxElements.length > 0) {
		// deactivate effect if Reduced Motion is enabled
		if (Util.osHasReducedMotion() || !intersectionObserverSupported) {
			fxRemoveClasses();
			return;
		}
		//on small devices, do not animate elements -> reveal all
		if( fxDisabled(fxElements[0]) ) {
			fxRevealAll();
			return;
		}

		var fxRevealDelta = 120; // amount (in pixel) the element needs to enter the viewport to be revealed - if not custom value (data-reveal-fx-delta)
		
		var viewportHeight = window.innerHeight,
			fxChecking = false,
			fxRevealedItems = [],
			fxElementDelays = fxGetDelays(), //elements animation delay
			fxElementDeltas = fxGetDeltas(); // amount (in px) the element needs enter the viewport to be revealed (default value is fxRevealDelta) 
		
		
		// add event listeners
		window.addEventListener('load', fxReveal);
		window.addEventListener('resize', fxResize);

		// observe reveal elements
		var observer = [];
		initObserver();

		function initObserver() {
			for(var i = 0; i < fxElements.length; i++) {
				observer[i] = new IntersectionObserver(
					function(entries, observer) { 
						if(entries[0].isIntersecting) {
							fxRevealItemObserver(entries[0].target);
							observer.unobserve(entries[0].target);
						}
					}, 
					{rootMargin: "0px 0px -"+fxElementDeltas[i]+"px 0px"}
				);
	
				observer[i].observe(fxElements[i]);
			}
		};

		function fxRevealAll() { // reveal all elements - small devices
			for(var i = 0; i < fxElements.length; i++) {
				Util.addClass(fxElements[i], 'reveal-fx--is-visible');
			}
		};

		function fxResize() { // on resize - check new window height and reveal visible elements
			if(fxChecking) return;
			fxChecking = true;
			(!window.requestAnimationFrame) ? setTimeout(function(){fxReset();}, 250) : window.requestAnimationFrame(fxReset);
		};

		function fxReset() {
			viewportHeight = window.innerHeight;
			fxReveal();
		};

		function fxReveal() { // reveal visible elements
			for(var i = 0; i < fxElements.length; i++) {(function(i){
				if(fxRevealedItems.indexOf(i) != -1 ) return; //element has already been revelead
				if(fxElementIsVisible(fxElements[i], i)) {
					fxRevealItem(i);
					fxRevealedItems.push(i);
				}})(i); 
			}
			fxResetEvents(); 
			fxChecking = false;
		};

		function fxRevealItem(index) {
			if(fxElementDelays[index] && fxElementDelays[index] != 0) {
				// wait before revealing element if a delay was added
				setTimeout(function(){
					Util.addClass(fxElements[index], 'reveal-fx--is-visible');
				}, fxElementDelays[index]);
			} else {
				Util.addClass(fxElements[index], 'reveal-fx--is-visible');
			}
		};

		function fxRevealItemObserver(item) {
			var index = Util.getIndexInArray(fxElements, item);
			if(fxRevealedItems.indexOf(index) != -1 ) return; //element has already been revelead
			fxRevealItem(index);
			fxRevealedItems.push(index);
			fxResetEvents(); 
			fxChecking = false;
		};

		function fxGetDelays() { // get anmation delays
			var delays = [];
			for(var i = 0; i < fxElements.length; i++) {
				delays.push( fxElements[i].getAttribute('data-reveal-fx-delay') ? parseInt(fxElements[i].getAttribute('data-reveal-fx-delay')) : 0);
			}
			return delays;
		};

		function fxGetDeltas() { // get reveal delta
			var deltas = [];
			for(var i = 0; i < fxElements.length; i++) {
				deltas.push( fxElements[i].getAttribute('data-reveal-fx-delta') ? parseInt(fxElements[i].getAttribute('data-reveal-fx-delta')) : fxRevealDelta);
			}
			return deltas;
		};

		function fxDisabled(element) { // check if elements need to be animated - no animation on small devices
			return !(window.getComputedStyle(element, '::before').getPropertyValue('content').replace(/'|"/g, "") == 'reveal-fx');
		};

		function fxElementIsVisible(element, i) { // element is inside viewport
			return (fxGetElementPosition(element) <= viewportHeight - fxElementDeltas[i]);
		};

		function fxGetElementPosition(element) { // get top position of element
			return element.getBoundingClientRect().top;
		};

		function fxResetEvents() { 
			if(fxElements.length > fxRevealedItems.length) return;
			// remove event listeners if all elements have been revealed
			window.removeEventListener('load', fxReveal);
			window.removeEventListener('resize', fxResize);
		};

		function fxRemoveClasses() {
			// Reduced Motion on or Intersection Observer not supported
			while(fxElements[0]) {
				// remove all classes starting with 'reveal-fx--'
				var classes = fxElements[0].getAttribute('class').split(" ").filter(function(c) {
					return c.lastIndexOf('reveal-fx--', 0) !== 0;
				});
				fxElements[0].setAttribute('class', classes.join(" ").trim());
				Util.removeClass(fxElements[0], 'reveal-fx');
			}
		};
	}
}());


  // File#: _1_swipe-content
  (function() {
	var SwipeContent = function(element) {
		this.element = element;
		this.delta = [false, false];
		this.dragging = false;
		this.intervalId = false;
		initSwipeContent(this);
	};

	function initSwipeContent(content) {
		content.element.addEventListener('mousedown', handleEvent.bind(content));
		content.element.addEventListener('touchstart', handleEvent.bind(content));
	};

	function initDragging(content) {
		//add event listeners
		content.element.addEventListener('mousemove', handleEvent.bind(content));
		content.element.addEventListener('touchmove', handleEvent.bind(content));
		content.element.addEventListener('mouseup', handleEvent.bind(content));
		content.element.addEventListener('mouseleave', handleEvent.bind(content));
		content.element.addEventListener('touchend', handleEvent.bind(content));
	};

	function cancelDragging(content) {
		//remove event listeners
		if(content.intervalId) {
			(!window.requestAnimationFrame) ? clearInterval(content.intervalId) : window.cancelAnimationFrame(content.intervalId);
			content.intervalId = false;
		}
		content.element.removeEventListener('mousemove', handleEvent.bind(content));
		content.element.removeEventListener('touchmove', handleEvent.bind(content));
		content.element.removeEventListener('mouseup', handleEvent.bind(content));
		content.element.removeEventListener('mouseleave', handleEvent.bind(content));
		content.element.removeEventListener('touchend', handleEvent.bind(content));
	};

	function handleEvent(event) {
		switch(event.type) {
			case 'mousedown':
			case 'touchstart':
				startDrag(this, event);
				break;
			case 'mousemove':
			case 'touchmove':
				drag(this, event);
				break;
			case 'mouseup':
			case 'mouseleave':
			case 'touchend':
				endDrag(this, event);
				break;
		}
	};

	function startDrag(content, event) {
		content.dragging = true;
		// listen to drag movements
		initDragging(content);
		content.delta = [parseInt(unify(event).clientX), parseInt(unify(event).clientY)];
		// emit drag start event
		emitSwipeEvents(content, 'dragStart', content.delta, event.target);
	};

	function endDrag(content, event) {
		cancelDragging(content);
		// credits: https://css-tricks.com/simple-swipe-with-vanilla-javascript/
		var dx = parseInt(unify(event).clientX), 
	    dy = parseInt(unify(event).clientY);
	  
	  // check if there was a left/right swipe
		if(content.delta && (content.delta[0] || content.delta[0] === 0)) {
	    var s = getSign(dx - content.delta[0]);
			
			if(Math.abs(dx - content.delta[0]) > 30) {
				(s < 0) ? emitSwipeEvents(content, 'swipeLeft', [dx, dy]) : emitSwipeEvents(content, 'swipeRight', [dx, dy]);	
			}
	    
	    content.delta[0] = false;
	  }
		// check if there was a top/bottom swipe
	  if(content.delta && (content.delta[1] || content.delta[1] === 0)) {
	  	var y = getSign(dy - content.delta[1]);

	  	if(Math.abs(dy - content.delta[1]) > 30) {
	    	(y < 0) ? emitSwipeEvents(content, 'swipeUp', [dx, dy]) : emitSwipeEvents(content, 'swipeDown', [dx, dy]);
	    }

	    content.delta[1] = false;
	  }
		// emit drag end event
	  emitSwipeEvents(content, 'dragEnd', [dx, dy]);
	  content.dragging = false;
	};

	function drag(content, event) {
		if(!content.dragging) return;
		// emit dragging event with coordinates
		(!window.requestAnimationFrame) 
			? content.intervalId = setTimeout(function(){emitDrag.bind(content, event);}, 250) 
			: content.intervalId = window.requestAnimationFrame(emitDrag.bind(content, event));
	};

	function emitDrag(event) {
		emitSwipeEvents(this, 'dragging', [parseInt(unify(event).clientX), parseInt(unify(event).clientY)]);
	};

	function unify(event) { 
		// unify mouse and touch events
		return event.changedTouches ? event.changedTouches[0] : event; 
	};

	function emitSwipeEvents(content, eventName, detail, el) {
		var trigger = false;
		if(el) trigger = el;
		// emit event with coordinates
		var event = new CustomEvent(eventName, {detail: {x: detail[0], y: detail[1], origin: trigger}});
		content.element.dispatchEvent(event);
	};

	function getSign(x) {
		if(!Math.sign) {
			return ((x > 0) - (x < 0)) || +x;
		} else {
			return Math.sign(x);
		}
	};

	window.SwipeContent = SwipeContent;
	
	//initialize the SwipeContent objects
	var swipe = document.getElementsByClassName('js-swipe-content');
	if( swipe.length > 0 ) {
		for( var i = 0; i < swipe.length; i++) {
			(function(i){new SwipeContent(swipe[i]);})(i);
		}
	}
}());
  // File#: _2_draggable-img-gallery
// Usage: codyhouse.co/license
(function() {
	var DragGallery = function(element) {
	  this.element = element;
	  this.list = this.element.getElementsByTagName('ul')[0];
	  this.imgs = this.list.children;
	  this.gestureHint = this.element.getElementsByClassName('drag-gallery__gesture-hint');// drag gesture hint
	  this.galleryWidth = getGalleryWidth(this); 
	  this.translate = 0; // store container translate value
	  this.dragStart = false; // start dragging position
	  // drag momentum option
	  this.dragMStart = false;
	  this.dragTimeMStart = false;
	  this.dragTimeMEnd = false;
	  this.dragMSpeed = false;
	  this.dragAnimId = false;
	  initDragGalleryEvents(this); 
	};
  
	function initDragGalleryEvents(gallery) {
	  initDragging(gallery); // init dragging
  
	  gallery.element.addEventListener('update-gallery-width', function(event){ // window resize
		gallery.galleryWidth = getGalleryWidth(gallery); 
		// reset translate value if not acceptable
		checkTranslateValue(gallery);
		setTranslate(gallery);
	  });
	   
	  if(intersectionObsSupported) initOpacityAnim(gallery); // init image animation
  
	  if(!reducedMotion && gallery.gestureHint.length > 0) initHintGesture(gallery); // init hint gesture element animation
  
	  initKeyBoardNav(gallery);
	};
  
	function getGalleryWidth(gallery) {
	  return gallery.list.scrollWidth - gallery.list.offsetWidth;
	};
  
	function initDragging(gallery) { // gallery drag
	  new SwipeContent(gallery.element);
	  gallery.element.addEventListener('dragStart', function(event){
		window.cancelAnimationFrame(gallery.dragAnimId);
		Util.addClass(gallery.element, 'drag-gallery--is-dragging'); 
		gallery.dragStart = event.detail.x;
		gallery.dragMStart = event.detail.x;
		gallery.dragTimeMStart = new Date().getTime();
		gallery.dragTimeMEnd = false;
		gallery.dragMSpeed = false;
		initDragEnd(gallery);
	  });
  
	  gallery.element.addEventListener('dragging', function(event){
		if(!gallery.dragStart) return;
		if(Math.abs(event.detail.x - gallery.dragStart) < 5) return;
		gallery.translate = Math.round(event.detail.x - gallery.dragStart + gallery.translate);
		gallery.dragStart = event.detail.x;
		checkTranslateValue(gallery);
		setTranslate(gallery);
	  });
	};
  
	function initDragEnd(gallery) {
	  gallery.element.addEventListener('dragEnd', function cb(event){
		gallery.element.removeEventListener('dragEnd', cb);
		Util.removeClass(gallery.element, 'drag-gallery--is-dragging');
		initMomentumDrag(gallery); // drag momentum
		gallery.dragStart = false;
	  });
	};
  
	function initKeyBoardNav(gallery) {
	  gallery.element.setAttribute('tabindex', 0);
	  // navigate gallery using right/left arrows
	  gallery.element.addEventListener('keyup', function(event){
		if( event.keyCode && event.keyCode == 39 || event.key && event.key.toLowerCase() == 'arrowright' ) {
		  keyboardNav(gallery, 'right');
		} else if(event.keyCode && event.keyCode == 37 || event.key && event.key.toLowerCase() == 'arrowleft') {
		  keyboardNav(gallery, 'left');
		}
	  });
	};
  
	function keyboardNav(gallery, direction) {
	  var delta = parseFloat(window.getComputedStyle(gallery.imgs[0]).marginRight) + gallery.imgs[0].offsetWidth;
	  gallery.translate = (direction == 'right') ? gallery.translate - delta : gallery.translate + delta;
	  checkTranslateValue(gallery);
	  setTranslate(gallery);
	};
  
	function checkTranslateValue(gallery) { // make sure translate is in the right interval
	  if(gallery.translate > 0) {
		gallery.translate = 0;
		gallery.dragMSpeed = 0;
	  }
	  if(Math.abs(gallery.translate) > gallery.galleryWidth) {
		gallery.translate = gallery.galleryWidth*-1;
		gallery.dragMSpeed = 0;
	  }
	};
  
	function setTranslate(gallery) {
	  gallery.list.style.transform = 'translateX('+gallery.translate+'px)';
	  gallery.list.style.msTransform = 'translateX('+gallery.translate+'px)';
	};
  
	function initOpacityAnim(gallery) { // animate img opacities on drag
	  for(var i = 0; i < gallery.imgs.length; i++) {
		var observer = new IntersectionObserver(opacityCallback.bind(gallery.imgs[i]), { threshold: [0, 0.1] });
			observer.observe(gallery.imgs[i]);
	  }
	};
  
	function opacityCallback(entries, observer) { // reveal images when they enter the viewport
	  var threshold = entries[0].intersectionRatio.toFixed(1);
		  if(threshold > 0) {
		Util.addClass(this, 'drag-gallery__item--visible');
		observer.unobserve(this);
	  }
	};
  
	function initMomentumDrag(gallery) {
	  // momentum effect when drag is over
	  if(reducedMotion) return;
	  var timeNow = new Date().getTime();
	  gallery.dragMSpeed = 0.95*(gallery.dragStart - gallery.dragMStart)/(timeNow - gallery.dragTimeMStart);
  
	  var currentTime = false;
  
	  function animMomentumDrag(timestamp) {
		if (!currentTime) currentTime = timestamp;         
		var progress = timestamp - currentTime;
		currentTime = timestamp;
		if(Math.abs(gallery.dragMSpeed) < 0.01) {
		  gallery.dragAnimId = false;
		  return;
		} else {
		  gallery.translate = Math.round(gallery.translate + (gallery.dragMSpeed*progress));
		  checkTranslateValue(gallery);
		  setTranslate(gallery);
		  gallery.dragMSpeed = gallery.dragMSpeed*0.95;
		  gallery.dragAnimId = window.requestAnimationFrame(animMomentumDrag);
		}
	  };
  
	  gallery.dragAnimId = window.requestAnimationFrame(animMomentumDrag);
	};
  
	function initHintGesture(gallery) { // show user a hint about gallery dragging
	  var observer = new IntersectionObserver(hintGestureCallback.bind(gallery.gestureHint[0]), { threshold: [0, 1] });
		  observer.observe(gallery.gestureHint[0]);
	};
  
	function hintGestureCallback(entries, observer) {
	  var threshold = entries[0].intersectionRatio.toFixed(1);
		  if(threshold > 0) {
		Util.addClass(this, 'drag-gallery__gesture-hint--animate');
		observer.unobserve(this);
	  }
	};
  
	//initialize the DragGallery objects
	var dragGallery = document.getElementsByClassName('js-drag-gallery'),
	  intersectionObsSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype),
	  reducedMotion = Util.osHasReducedMotion();
  
	if( dragGallery.length > 0 ) {
	  var dragGalleryArray = [];
	  for( var i = 0; i < dragGallery.length; i++) {
		(function(i){ 
		  if(!intersectionObsSupported || reducedMotion) Util.addClass(dragGallery[i], 'drag-gallery--anim-off');
		  dragGalleryArray.push(new DragGallery(dragGallery[i]));
		})(i);
	  }
  
	  // resize event
	  var resizingId = false,
		customEvent = new CustomEvent('update-gallery-width');
	  
	  window.addEventListener('resize', function() {
		clearTimeout(resizingId);
		resizingId = setTimeout(doneResizing, 500);
	  });
  
	  function doneResizing() {
		for( var i = 0; i < dragGalleryArray.length; i++) {
		  (function(i){dragGalleryArray[i].element.dispatchEvent(customEvent)})(i);
		};
	  };
	}
  }());

// File#: _2_anim-card
// Usage: codyhouse.co/license
(function() {
	var AnimCards = function(element) {
	  this.element = element;
	  this.list = this.element.getElementsByTagName('ul')[0];
	  this.cards = this.list.children;
	  this.reverseDirection = Util.hasClass(this.element, 'anim-cards--reverse');
	  this.translate = 0; // store container translate value
	  this.animationId = false;
	  this.animating = false;
	  this.paused = false;
	  // change speed of animation  
	  this.animationSpeed = 1; // > 1 to increse speed, < 1 to reduce; always > 0
	  initAnimCardsEvents(this);
	};
  
	function initAnimCardsEvents(cards) {
	  // init observer
	  var observer = new IntersectionObserver(animCardsObserve.bind(cards));
	  observer.observe(cards.element);
  
	  cards.element.addEventListener('update-card-width', function(event){ // reset animation on resize
		if(cards.animating) {
		  cancelPrevAnimation(cards);
		  if(!cards.paused) initAnimCards(cards);
		}
	  });
  
	  // play/pause button
	  cards.element.addEventListener('anim-cards', function(event) {
		playCards(cards);
	  });
	  cards.element.addEventListener('pause-cards', function(event) {
		pauseCards(cards);
	  });
  
	  // pause on hover
	  var pauseHover = cards.element.getAttribute('data-anim-cards-pause-hover');
	  if(pauseHover && pauseHover == 'on') {
		cards.element.addEventListener('mouseenter', function(){
		  pauseCards(cards);
		});
  
		cards.element.addEventListener('mouseleave', function(){
		  playCards(cards);
		});
	  }
	};
  
	function pauseCards(cards) { // pause cards - play/pause button or mouseenter
	  cards.paused = true;
	  if(cards.animating) {
		cancelPrevAnimation(cards);
		cards.timestamp = false;
	  }
	};
  
	function playCards(cards) { // play cards - play/pause button or mouseleave
	  cards.paused = false;
	  if(cards.animating) initAnimCards(cards);
	};
  
	function animCardsObserve(entries) {
	  if(entries[0].isIntersecting) {
		this.animating = true;
		if(!this.paused) initAnimCards(this); // init animation
	  } else {
		this.animating = false;
		cancelPrevAnimation(this);
	  }
	};
  
	function initAnimCards(cards) {
	  if(cards.paused) return;
	  cards.cardWidth = getAnimCardsWidth(cards);
	  cards.animationId = window.requestAnimationFrame(triggerAnimCards.bind(cards));
	};
  
	function triggerAnimCards(timestamp) {
	  cancelPrevAnimation(this);
	  if(!this.timestamp) this.timestamp = timestamp;
	  var translateIncrease = (this.timestamp - timestamp)*0.06*this.animationSpeed;
	  this.timestamp = timestamp;
	  updateAnimCardsTranslate(this, translateIncrease);
	  updateAnimCardsList(this);
	  setTranslate(this);
	  this.animationId = window.requestAnimationFrame(triggerAnimCards.bind(this));
	};
  
	function updateAnimCardsTranslate(cards, translate) {
	  cards.translate = cards.reverseDirection ? cards.translate - translate : cards.translate + translate;
	  cards.translate = Math.round(Math.abs(cards.translate));
	  if(!cards.reverseDirection) cards.translate = cards.translate*-1;
	};
  
	function updateAnimCardsList(cards) {
	  if(Math.abs(cards.translate) <= cards.cardWidth) return;
	  // need to remove first item from the list and append it to the end of list
	  cards.translate = Math.abs(cards.translate) - cards.cardWidth;
	  if(!cards.reverseDirection) cards.translate = cards.translate*-1;
	  var clone = cards.cards[0].cloneNode(true);
	  cards.list.removeChild(cards.cards[0]);
	  cards.list.appendChild(clone);
	};
  
	function setTranslate(cards) {
	  cards.list.style.transform = 'translateX('+cards.translate+'px)';
	  cards.list.style.msTransform = 'translateX('+cards.translate+'px)';
	};
  
	function getAnimCardsWidth(cards) {
	  return parseFloat(window.getComputedStyle(cards.cards[0]).marginRight) + cards.cards[0].offsetWidth;
	};
  
	function cancelPrevAnimation(cards) {
	  if(cards.animationId) {
		window.cancelAnimationFrame(cards.animationId);
		cards.animationId = false;
	  }
	};
  
	function initAnimCardsController(controller) {
	  // play/pause btn controller
	  var cardsContainer = document.getElementById(controller.getAttribute('aria-controls'));
	  if(!cardsContainer) return;
	  var cardsList = cardsContainer.getElementsByClassName('js-anim-cards');
	  if(cardsList.length < 1) return;
  
	  // detect click
	  controller.addEventListener('click', function(event){
		var playAnimation = controller.getAttribute('aria-pressed') == 'true';
		var animEvent = playAnimation ? 'anim-cards' : 'pause-cards';
		playAnimation ? controller.setAttribute('aria-pressed', 'false') : controller.setAttribute('aria-pressed', 'true');
		for(var i = 0; i < cardsList.length; i++) {
		  cardsList[i].dispatchEvent(new CustomEvent(animEvent));
		}
	  });
	};
  
	//initialize the AnimCards objects
	var animCards = document.getElementsByClassName('js-anim-cards'),
	  requestAnimationFrameSupported = window.requestAnimationFrame,
	  reducedMotion = Util.osHasReducedMotion(),
	  intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
  
	  if( animCards.length > 0 ) {
	  var animCardsArray = [];
		  for( var i = 0; i < animCards.length; i++) {
		if(!requestAnimationFrameSupported || reducedMotion || !intersectionObserverSupported) {
		  // animation is off if requestAnimationFrame/IntersectionObserver is not supported or reduced motion is on
		  Util.addClass(animCards[i], 'anim-cards--anim-off');
		} else {(function(i){animCardsArray.push(new AnimCards(animCards[i]));})(i);}
	  }
  
	  if(animCardsArray.length > 0) {
		var resizingId = false,
		  customEvent = new CustomEvent('update-card-width');
		
		window.addEventListener('resize', function() {
		  clearTimeout(resizingId);
		  resizingId = setTimeout(doneResizing, 500);
		});
  
		function doneResizing() {
		  for( var i = 0; i < animCardsArray.length; i++) {
			(function(i){animCardsArray[i].element.dispatchEvent(customEvent)})(i);
		  };
		};
	  };
  
	  // check play/pause buttons
	  var animCardsControl = document.getElementsByClassName('js-anim-cards-control');
	  if(animCardsControl.length > 0) {
		for( var i = 0; i < animCardsControl.length; i++) {
		  if(!requestAnimationFrameSupported || reducedMotion || !intersectionObserverSupported) {
			Util.addClass(animCardsControl[i], 'is-hidden');
		  } else {
			(function(i){initAnimCardsController(animCardsControl[i]);})(i);
		  } 
		}
	  }
	}
  }());