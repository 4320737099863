// File#: _2_anim-card
// Usage: codyhouse.co/license
(function() {
    var AnimCards = function(element) {
      this.element = element;
      this.list = this.element.getElementsByTagName('ul')[0];
      this.cards = this.list.children;
      this.reverseDirection = Util.hasClass(this.element, 'anim-cards--reverse');
      this.translate = 0; // store container translate value
      this.animationId = false;
      this.animating = false;
      this.paused = false;
      // change speed of animation  
      this.animationSpeed = 1; // > 1 to increse speed, < 1 to reduce; always > 0
      initAnimCardsEvents(this);
    };
  
    function initAnimCardsEvents(cards) {
      // init observer
      var observer = new IntersectionObserver(animCardsObserve.bind(cards));
      observer.observe(cards.element);
  
      cards.element.addEventListener('update-card-width', function(event){ // reset animation on resize
        if(cards.animating) {
          cancelPrevAnimation(cards);
          if(!cards.paused) initAnimCards(cards);
        }
      });
  
      // play/pause button
      cards.element.addEventListener('anim-cards', function(event) {
        playCards(cards);
      });
      cards.element.addEventListener('pause-cards', function(event) {
        pauseCards(cards);
      });
  
      // pause on hover
      var pauseHover = cards.element.getAttribute('data-anim-cards-pause-hover');
      if(pauseHover && pauseHover == 'on') {
        cards.element.addEventListener('mouseenter', function(){
          pauseCards(cards);
        });
  
        cards.element.addEventListener('mouseleave', function(){
          playCards(cards);
        });
      }
    };
  
    function pauseCards(cards) { // pause cards - play/pause button or mouseenter
      cards.paused = true;
      if(cards.animating) {
        cancelPrevAnimation(cards);
        cards.timestamp = false;
      }
    };
  
    function playCards(cards) { // play cards - play/pause button or mouseleave
      cards.paused = false;
      if(cards.animating) initAnimCards(cards);
    };
  
    function animCardsObserve(entries) {
      if(entries[0].isIntersecting) {
        this.animating = true;
        if(!this.paused) initAnimCards(this); // init animation
      } else {
        this.animating = false;
        cancelPrevAnimation(this);
      }
    };
  
    function initAnimCards(cards) {
      if(cards.paused) return;
      cards.cardWidth = getAnimCardsWidth(cards);
      cards.animationId = window.requestAnimationFrame(triggerAnimCards.bind(cards));
    };
  
    function triggerAnimCards(timestamp) {
      cancelPrevAnimation(this);
      if(!this.timestamp) this.timestamp = timestamp;
      var translateIncrease = (this.timestamp - timestamp)*0.06*this.animationSpeed;
      this.timestamp = timestamp;
      updateAnimCardsTranslate(this, translateIncrease);
      updateAnimCardsList(this);
      setTranslate(this);
      this.animationId = window.requestAnimationFrame(triggerAnimCards.bind(this));
    };
  
    function updateAnimCardsTranslate(cards, translate) {
      cards.translate = cards.reverseDirection ? cards.translate - translate : cards.translate + translate;
      cards.translate = Math.round(Math.abs(cards.translate));
      if(!cards.reverseDirection) cards.translate = cards.translate*-1;
    };
  
    function updateAnimCardsList(cards) {
      if(Math.abs(cards.translate) <= cards.cardWidth) return;
      // need to remove first item from the list and append it to the end of list
      cards.translate = Math.abs(cards.translate) - cards.cardWidth;
      if(!cards.reverseDirection) cards.translate = cards.translate*-1;
      var clone = cards.cards[0].cloneNode(true);
      cards.list.removeChild(cards.cards[0]);
      cards.list.appendChild(clone);
    };
  
    function setTranslate(cards) {
      cards.list.style.transform = 'translateX('+cards.translate+'px)';
      cards.list.style.msTransform = 'translateX('+cards.translate+'px)';
    };
  
    function getAnimCardsWidth(cards) {
      return parseFloat(window.getComputedStyle(cards.cards[0]).marginRight) + cards.cards[0].offsetWidth;
    };
  
    function cancelPrevAnimation(cards) {
      if(cards.animationId) {
        window.cancelAnimationFrame(cards.animationId);
        cards.animationId = false;
      }
    };
  
    function initAnimCardsController(controller) {
      // play/pause btn controller
      var cardsContainer = document.getElementById(controller.getAttribute('aria-controls'));
      if(!cardsContainer) return;
      var cardsList = cardsContainer.getElementsByClassName('js-anim-cards');
      if(cardsList.length < 1) return;
  
      // detect click
      controller.addEventListener('click', function(event){
        var playAnimation = controller.getAttribute('aria-pressed') == 'true';
        var animEvent = playAnimation ? 'anim-cards' : 'pause-cards';
        playAnimation ? controller.setAttribute('aria-pressed', 'false') : controller.setAttribute('aria-pressed', 'true');
        for(var i = 0; i < cardsList.length; i++) {
          cardsList[i].dispatchEvent(new CustomEvent(animEvent));
        }
      });
    };
  
    //initialize the AnimCards objects
    var animCards = document.getElementsByClassName('js-anim-cards'),
      requestAnimationFrameSupported = window.requestAnimationFrame,
      reducedMotion = Util.osHasReducedMotion(),
      intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
  
      if( animCards.length > 0 ) {
      var animCardsArray = [];
          for( var i = 0; i < animCards.length; i++) {
        if(!requestAnimationFrameSupported || reducedMotion || !intersectionObserverSupported) {
          // animation is off if requestAnimationFrame/IntersectionObserver is not supported or reduced motion is on
          Util.addClass(animCards[i], 'anim-cards--anim-off');
        } else {(function(i){animCardsArray.push(new AnimCards(animCards[i]));})(i);}
      }
  
      if(animCardsArray.length > 0) {
        var resizingId = false,
          customEvent = new CustomEvent('update-card-width');
        
        window.addEventListener('resize', function() {
          clearTimeout(resizingId);
          resizingId = setTimeout(doneResizing, 500);
        });
  
        function doneResizing() {
          for( var i = 0; i < animCardsArray.length; i++) {
            (function(i){animCardsArray[i].element.dispatchEvent(customEvent)})(i);
          };
        };
      };
  
      // check play/pause buttons
      var animCardsControl = document.getElementsByClassName('js-anim-cards-control');
      if(animCardsControl.length > 0) {
        for( var i = 0; i < animCardsControl.length; i++) {
          if(!requestAnimationFrameSupported || reducedMotion || !intersectionObserverSupported) {
            Util.addClass(animCardsControl[i], 'is-hidden');
          } else {
            (function(i){initAnimCardsController(animCardsControl[i]);})(i);
          } 
        }
      }
    }
  }());

  // File#: _1_accordion
// Usage: codyhouse.co/license
(function() {
	var Accordion = function(element) {
		this.element = element;
		this.items = Util.getChildrenByClassName(this.element, 'js-accordion__item');
		this.version = this.element.getAttribute('data-version') ? '-'+this.element.getAttribute('data-version') : '';
		this.showClass = 'accordion'+this.version+'__item--is-open';
		this.animateHeight = (this.element.getAttribute('data-animation') == 'on');
		this.multiItems = !(this.element.getAttribute('data-multi-items') == 'off'); 
		// deep linking options
		this.deepLinkOn = this.element.getAttribute('data-deep-link') == 'on';
		// init accordion
		this.initAccordion();
	};

	Accordion.prototype.initAccordion = function() {
		//set initial aria attributes
		for( var i = 0; i < this.items.length; i++) {
			var button = this.items[i].getElementsByTagName('button')[0],
				content = this.items[i].getElementsByClassName('js-accordion__panel')[0],
				isOpen = Util.hasClass(this.items[i], this.showClass) ? 'true' : 'false';
			Util.setAttributes(button, {'aria-expanded': isOpen, 'aria-controls': 'accordion-content-'+i, 'id': 'accordion-header-'+i});
			Util.addClass(button, 'js-accordion__trigger');
			Util.setAttributes(content, {'aria-labelledby': 'accordion-header-'+i, 'id': 'accordion-content-'+i});
		}

		//listen for Accordion events
		this.initAccordionEvents();

		// check deep linking option
		this.initDeepLink();
	};

	Accordion.prototype.initAccordionEvents = function() {
		var self = this;

		this.element.addEventListener('click', function(event) {
			var trigger = event.target.closest('.js-accordion__trigger');
			//check index to make sure the click didn't happen inside a children accordion
			if( trigger && Util.getIndexInArray(self.items, trigger.parentElement) >= 0) self.triggerAccordion(trigger);
		});
	};

	Accordion.prototype.triggerAccordion = function(trigger) {
		var bool = (trigger.getAttribute('aria-expanded') === 'true');

		this.animateAccordion(trigger, bool, false);

		if(!bool && this.deepLinkOn) {
			history.replaceState(null, '', '#'+trigger.getAttribute('aria-controls'));
		}
	};

	Accordion.prototype.animateAccordion = function(trigger, bool, deepLink) {
		var self = this;
		var item = trigger.closest('.js-accordion__item'),
			content = item.getElementsByClassName('js-accordion__panel')[0],
			ariaValue = bool ? 'false' : 'true';

		if(!bool) Util.addClass(item, this.showClass);
		trigger.setAttribute('aria-expanded', ariaValue);
		self.resetContentVisibility(item, content, bool);

		if( !this.multiItems && !bool || deepLink) this.closeSiblings(item);
	};

	Accordion.prototype.resetContentVisibility = function(item, content, bool) {
		Util.toggleClass(item, this.showClass, !bool);
		content.removeAttribute("style");
		if(bool && !this.multiItems) { // accordion item has been closed -> check if there's one open to move inside viewport 
			this.moveContent();
		}
	};

	Accordion.prototype.closeSiblings = function(item) {
		//if only one accordion can be open -> search if there's another one open
		var index = Util.getIndexInArray(this.items, item);
		for( var i = 0; i < this.items.length; i++) {
			if(Util.hasClass(this.items[i], this.showClass) && i != index) {
				this.animateAccordion(this.items[i].getElementsByClassName('js-accordion__trigger')[0], true, false);
				return false;
			}
		}
	};

	Accordion.prototype.moveContent = function() { // make sure title of the accordion just opened is inside the viewport
		var openAccordion = this.element.getElementsByClassName(this.showClass);
		if(openAccordion.length == 0) return;
		var boundingRect = openAccordion[0].getBoundingClientRect();
		if(boundingRect.top < 0 || boundingRect.top > window.innerHeight) {
			var windowScrollTop = window.scrollY || document.documentElement.scrollTop;
			window.scrollTo(0, boundingRect.top + windowScrollTop);
		}
	};

	Accordion.prototype.initDeepLink = function() {
		if(!this.deepLinkOn) return;
		var hash = window.location.hash.substr(1);
		if(!hash || hash == '') return;
		var trigger = this.element.querySelector('.js-accordion__trigger[aria-controls="'+hash+'"]');
		if(trigger && trigger.getAttribute('aria-expanded') !== 'true') {
			this.animateAccordion(trigger, false, true);
			setTimeout(function(){trigger.scrollIntoView(true);});
		}
	};

	window.Accordion = Accordion;
	
	//initialize the Accordion objects
	var accordions = document.getElementsByClassName('js-accordion');
	if( accordions.length > 0 ) {
		for( var i = 0; i < accordions.length; i++) {
			(function(i){new Accordion(accordions[i]);})(i);
		}
	}
}());

// File#: _1_tilted-img-slideshow
// Usage: codyhouse.co/license
(function() {
    var TiltedSlideshow = function(element) {
      this.element = element;
      this.list = this.element.getElementsByClassName('js-tilted-slideshow__list')[0];
      this.images = this.list.getElementsByClassName('js-tilted-slideshow__item');
      this.selectedIndex = 0;
      this.animating = false;
      // classes
      this.orderClasses = ['tilted-slideshow__item--top', 'tilted-slideshow__item--middle', 'tilted-slideshow__item--bottom'];
      this.moveClasses = ['tilted-slideshow__item--move-out', 'tilted-slideshow__item--move-in'];
      this.interactedClass = 'tilted-slideshow--interacted';
      initTiltedSlideshow(this);
    };
  
    function initTiltedSlideshow(slideshow) {
      if(!animateImgs) removeTransitions(slideshow);
      
      slideshow.list.addEventListener('click', function(event) {
        Util.addClass(slideshow.element, slideshow.interactedClass);
        animateImgs ? animateImages(slideshow) : switchImages(slideshow);
      });
    };
  
    function removeTransitions(slideshow) {
      // if reduced motion is on or css variables are not supported -> do not animate images
      for(var i = 0; i < slideshow.images.length; i++) {
        slideshow.images[i].style.transition = 'none';
      }
    };
  
    function switchImages(slideshow) {
      // if reduced motion is on or css variables are not supported -> switch images without animation
      resetOrderClasses(slideshow);
      resetSelectedIndex(slideshow);
    };
  
    function resetSelectedIndex(slideshow) {
      // update the index of the top image
      slideshow.selectedIndex = resetIndex(slideshow, slideshow.selectedIndex + 1);
    };
  
    function resetIndex(slideshow, index) {
      // make sure index is < 3
      if(index >= slideshow.images.length) index = index - slideshow.images.length;
      return index;
    };
  
    function resetOrderClasses(slideshow) {
      // update the orderClasses for each images
      if(!animateImgs) {
        // top image -> remove top class and add bottom class
        Util.addClass(slideshow.images[slideshow.selectedIndex], slideshow.orderClasses[2]);
        Util.removeClass(slideshow.images[slideshow.selectedIndex], slideshow.orderClasses[0]);
      }
  
      // middle image -> remove middle class and add top class
      var middleImage = slideshow.images[resetIndex(slideshow, slideshow.selectedIndex + 1)];
      Util.addClass(middleImage, slideshow.orderClasses[0]);
      Util.removeClass(middleImage, slideshow.orderClasses[1]);
  
      // bottom image -> remove bottom class and add middle class
      var bottomImage = slideshow.images[resetIndex(slideshow, slideshow.selectedIndex + 2)];
      Util.addClass(bottomImage, slideshow.orderClasses[1]);
      Util.removeClass(bottomImage, slideshow.orderClasses[2]);
    };
  
    function animateImages(slideshow) {
      if(slideshow.animating) return;
      slideshow.animating = true;
  
      // reset order classes for middle/bottom images
      resetOrderClasses(slideshow);
      
      // animate top image
      var topImage = slideshow.images[slideshow.selectedIndex];
      // remove top class and add move out class
      Util.removeClass(topImage, slideshow.orderClasses[0]);
      Util.addClass(topImage, slideshow.moveClasses[0]);
      
      topImage.addEventListener('transitionend', function cb(event) {
        // remove transition
              topImage.style.transition = 'none';
              topImage.removeEventListener("transitionend", cb);
        
        setTimeout(function(){
          // add bottom + move-in class, remove move-out class
          Util.removeClass(topImage, slideshow.moveClasses[0]);
          Util.addClass(topImage, slideshow.moveClasses[1]+' '+ slideshow.orderClasses[2]);
          setTimeout(function(){
            topImage.style.transition = '';
            // remove move-in class
            Util.removeClass(topImage, slideshow.moveClasses[1]);
            topImage.addEventListener('transitionend', function cbn(event) {
              // reset animating property and selectedIndex index
              resetSelectedIndex(slideshow);
              slideshow.animating = false;
              topImage.removeEventListener("transitionend", cbn);
            });
          }, 10);
        }, 10);
          });
    };
  
    var tiltedSlideshow = document.getElementsByClassName('js-tilted-slideshow'),
      animateImgs = !Util.osHasReducedMotion() && ('CSS' in window) && CSS.supports('color', 'var(--color-var)');
    if(tiltedSlideshow.length > 0) {
      for(var i = 0; i < tiltedSlideshow.length; i++) {
        new TiltedSlideshow(tiltedSlideshow[i]);
      }
    }
  }());